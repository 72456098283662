import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Probate",
      content: (
        <>
          <p>
            Probate is a judicial process where a court reviews the assets of a
            deceased person, determines who the inheritors are, and orders how
            those assets will be distributed. It can take up to a year to
            complete.
          </p>
        </>
      ),
    },
    {
      title: "Estate",
      content: (
        <>
          <p>
            The term ‘estate’ refers to all property owned by the decedent at
            their death, including real property and personal property. All
            estate assets are collected and managed by the personal
            representative during probate.
          </p>
        </>
      ),
    },
    {
      title: "Administrator",
      content: (
        <>
          <p>
            An administrator is a personal representative appointed by the court
            to administer an estate when someone dies without a will or if the
            executor named in the decedent’s will cannot carry out their duties.
          </p>
        </>
      ),
    },
    {
      title: "Executor",
      content: (
        <>
          <p>
            An executor is a personal representative appointed by the court to
            administer an estate when someone dies with a will. The executor is
            usually named in the will.
          </p>
        </>
      ),
    },

    {
      title: "Heir",
      content: (
        <>
          <p>
            An heir is an individual or entity legally entitled to the assets of
            someone who has passed away.
          </p>
        </>
      ),
    },
    {
      title: "Decedent",
      content: (
        <>
          <p>A decedent is a person who has passed away.</p>
        </>
      ),
    },
    {
      title: "Intestate",
      content: (
        <>
          <p>
            The term intestate refers to dying without a valid will or without a
            valid distribution in a will.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Probate Litigation Lawyer San Diego | Brierton Jones"
        description="Do you need a top probate litigation lawyer in San Diego? Brierton, Jones & Jones, LLP connects you with what you need. Schedule a consultation today! "
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pb-6 pt-12 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  WHEN DISPUTES ARISE
                </div>
                <h1>San Diego Probate Litigation Attorney</h1>
                <p>
                  When an estate goes through{" "}
                  <a href="https://briertonjones.com/probate-attorney-san-diego/">
                    probate
                  </a>
                  , it can be an emotional time for all parties involved. At
                  Brierton, Jones & Jones, LLP, our expert probate litigation
                  attorneys will expertly guide you through this process. If a
                  probate proceeding becomes contested, you need qualified legal
                  counsel to represent and protect your interests.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/7.0 Probate Litigation/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>When Should I Hire a Probate Litigation Attorney?</h2>
                <p>
                  When a person passes away, their assets are distributed to
                  their rightful heirs or beneficiaries, whether there is a
                  will, trust, or no legal document outlining their wishes. In
                  many cases, a probate court will oversee this process to
                  ensure the assets are legally and properly transferred.
                </p>
                <p>
                  It’s best to seek help from an experienced probate litigation
                  attorney when:
                </p>
                <ul className="mb-6 list-disc pl-6">
                  <li>A will or trust is being challenged or contested</li>
                  <li>
                    The accountings or creditors’ claims are being contested
                  </li>
                  <li>
                    There’s a dispute over a particular person making a claim on
                    the property or assets
                  </li>
                  <li>
                    A trustee has breached their fiduciary duties, meaning they
                    are not legally fulfilling their duties to ensure the
                    decedent’s wishes are properly being carried out, or
                  </li>
                  <li>
                    There is a question of undue influence or mental capacity of
                    the decedent
                  </li>
                </ul>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>
                  If you’re involved in a probate dispute and are considering
                  hiring a probate litigation lawyer to help resolve the
                  situation, here are some of the common terms to familiarize
                  yourself with:
                </p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={6} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="When you need legal help with a probate dispute, contact our team of San Diego probate litigation attorneys." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Facebook/07 Probate Litigation.jpg"
      }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Twitter/07 Probate Litigation.jpg"
      }
    ) {
      publicURL
    }
  }
`;

export default Page;
